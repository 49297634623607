<template>
  <div class="flex justify-content-start group-filters visible-sm">
    <custom-input
      :delay="800"
      name="staff-passings-sort"
      label="Название"
      :inputWidth="100"
      cleanable
      v-model="searchParams.nameField"
    />
  </div>
  <div v-if="companies">
    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="70"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :suppressRowTransform="true"
      style="width: 100%"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
      @filterChanged="filterChanged"
    ></ag-grid-vue>

    <v-pagination
      v-if="gridApi"
      v-model="page"
      @update:modelValue="changePage"
      :pages="gridApi.paginationGetTotalPages()"
      :range-size="1"
      active-color="transparent"
    />

    <div
      v-if="gridApi && gridApi.paginationGetTotalPages()"
      class="pagination-info"
    >
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{ gridApi.paginationProxy.bottomDisplayedRowIndex + 1 }} из
        {{ gridApi.paginationGetRowCount() }}
      </p>
      <p>
        Страница {{ gridApi.paginationGetCurrentPage() + 1 }} из
        {{ gridApi.paginationGetTotalPages() }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import EditRenderer from "./CellRenderers/EditRenderer.vue";
import ImageRenderer from "./CellRenderers/ImageRenderer.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import Preloader from "../Technical/Preloader.vue";
import CustomTextFilter from "./FiltersComponents/CustomTextFilter.vue";
import TextLinkRenderer from "./CellRenderers/TextLinkRenderer.vue";
import CustomInput from "../Forms/Fields/CustomInput.vue";
export default {
  name: "staff-table",
  components: {
    VPagination,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    EditRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ImageRenderer,
    Preloader,
    // eslint-disable-next-line vue/no-unused-components
    TextLinkRenderer,
    CustomInput,
    // eslint-disable-next-line vue/no-unused-components
    CustomTextFilter,
  },
  setup() {
    const store = useStore(),
      gridApi = ref(null),
      columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
      page = ref(1),
      isPreloader = ref(false),
      searchParams = reactive({
        nameField: "",
      });
    const companies = computed(() => store.state.company.companies),
      rowData = computed(() => {
        return companies.value.map((company) => {
          return {
            id: company.id,
            name: company.name,
            edit: company.id,
          };
        });
      });

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "name",
          headerName: "Название",
          wrapText: true,
          minWidth: 255,
          cellRenderer: "TextLinkRenderer",
          cellRendererParams: {
            source: "company",
          },
          filter: "CustomTextFilter",
          filterParams: {
            filterName: "Поиск по названию",
          },
        },
        {
          field: "edit",
          headerName: "",
          width: 70,
          flex: 0,
          cellRenderer: "EditRenderer",
          cellRendererParams: {
            source: "company",
          },
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    onBeforeMount(() => {
      store.dispatch("company/getCompanies");
    });

    const changePage = (page) => {
      gridApi.value.paginationGoToPage(page - 1);
    };

    watch(searchParams, () => {
      if (searchParams.nameField.length >= 3) {
        gridApi.value.setQuickFilter(
          `${searchParams.nameField.length >= 3 ? searchParams.nameField : ""}`
        );
      } else {
        gridApi.value.setQuickFilter(null);
      }
      page.value = 1;
    });

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    const filterChanged = () => {
      page.value = 2;
      page.value = 1;
    };

    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      companies,
      searchParams,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
      filterChanged,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
